import React from 'react';
import Mood from "./Mood";
import { Button, ButtonGroup, ListGroup } from 'react-bootstrap';

const MoodList = ({moodList, handleFilter, handleDelete}) => {

    const [filterActive, setFilterActive] = React.useState(false);

    return (
        <div>
            {moodList.length > 0 && <h3>Mood data</h3>}
            <ListGroup>
            {moodList.map(m => (
                <ListGroup.Item variant={m.moodType === 'good' ? 'success' : 'danger'} key={m.id}><Mood mood={m} handleDelete={handleDelete} /></ListGroup.Item>
            ))}
            </ListGroup>

            {(moodList.length > 0 || filterActive) && <div>
                <ButtonGroup id='moodListActions'>
                    <Button 
                        variant='outline-secondary'
                        onClick={() => {
                            handleFilter("good");
                            setFilterActive(true);
                        }}>Filter good</Button>
                    <Button 
                        variant='outline-secondary'
                        onClick={() => {
                            handleFilter("bad");
                            setFilterActive(true);
                        }}>Filter bad</Button>
                    <Button
                        variant='outline-secondary'
                        onClick={() => {
                            handleFilter("all");
                            setFilterActive(false);
                        }}>Show all</Button>
                </ButtonGroup>
                
            </div>}
  
        </div>
    );
};

export default MoodList;