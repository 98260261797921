import React from 'react';
import { Badge } from 'react-bootstrap';

const Header = () => {
    return (
        <header>
            <h1>FeelGood.GPT <Badge bg="info" pill>alpha</Badge></h1>
            <h3>Analyze what improves or hinders your mood with AI</h3>
        </header>
    );
};

export default Header;
