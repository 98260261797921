import React from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';


const AnalysisResults = ({ happyReasons, happyIdeasToConsider, sadReasons, sadSolutionsToConsider }) => {

    const HappyThemes = React.memo(() => (<ListGroup>
        {happyReasons.map(r => <ListGroup.Item key={r}>{r}</ListGroup.Item>)}
    </ListGroup>));

    const HappyIdeas = React.memo(() => (<ListGroup>
        {happyIdeasToConsider.map(r => <ListGroup.Item key={r}>{r}</ListGroup.Item>)}
    </ListGroup>));

    const BadThemes = React.memo(() => (<ListGroup>
        {sadReasons.map(r => <ListGroup.Item  key={r}>{r}</ListGroup.Item >)}
    </ListGroup>));

    const BadSolutions = React.memo(() => (<ListGroup>
        {sadSolutionsToConsider.map(r => <ListGroup.Item  key={r}>{r}</ListGroup.Item >)}
    </ListGroup>));

    return (<div>

        {happyReasons.length === 0 && <span>No results</span>}

        <Accordion defaultActiveKey="0" alwaysOpen>
            {happyReasons.length > 0 && <Accordion.Item eventKey="0">
                <Accordion.Header>Common themes that make you feel good</Accordion.Header>
                <Accordion.Body>
                    <HappyThemes />
                </Accordion.Body>
            </Accordion.Item>}
            {happyIdeasToConsider.length > 0 && <Accordion.Item eventKey="1">
                <Accordion.Header>Ideas for more things to do</Accordion.Header>
                <Accordion.Body>
                    <HappyIdeas />
                </Accordion.Body>
            </Accordion.Item>}
            {sadReasons.length > 0 && <Accordion.Item eventKey="2">
                <Accordion.Header>Common themes that make you feel bad</Accordion.Header>
                <Accordion.Body>
                    <BadThemes />
                </Accordion.Body>
            </Accordion.Item>}
            {sadSolutionsToConsider.length > 0 && <Accordion.Item eventKey="3">
                <Accordion.Header>Solutions to consider</Accordion.Header>
                <Accordion.Body>
                    <BadSolutions />
                </Accordion.Body>
            </Accordion.Item>}
        </Accordion>

    </div>);

}

export default AnalysisResults;