import React from 'react';
import { Button } from 'react-bootstrap';

const Mood = ({mood, handleDelete}) => {

    const handleDeleteClick = (e) => {
        e.preventDefault();
        handleDelete(mood.id);
    };

    return (
        <div id={mood.id} key={mood.id + mood.reason} 
            name="mood" value={mood.id}>
            {mood.reason}
            <Button className='deleteMoodButton' variant='outline-danger' onClick={handleDeleteClick}>Delete</Button>
        </div>
    );
};

export default Mood;