import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const MoodForm = ({ addMood }) => {

    const [userInput, setUserInput] = useState('');
    const [moodType, setMoodType] = useState('good');
    const [show, setShow] = useState(false);

    const handleSaveChanges = () => {
        setShow(false);
        addMood(userInput, moodType);
        setUserInput("");
    }

    const handleClose = () => { 
        setShow(false);
        setUserInput("");
    }
    
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        setUserInput(e.currentTarget.value)
    }

    const handleMoodChange = (e) => {
        setMoodType(e.currentTarget.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addMood(userInput, moodType);
        setUserInput("");
    }
    
    return (
        <div>

            <Button variant="primary" id='addMoodButton' onClick={handleShow}>
                Capture Mood and Reason
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add mood</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>

                    <Form.Group controlId='howFeel' className='mb-3'>
                        <Form.Label>How do you feel?</Form.Label>
                        <Form.Control as='select' value={moodType} onChange={handleMoodChange}>
                            <option value="good">Good</option>
                            <option value="bad">Bad</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='reason' className='mb-3'>
                        <Form.Label>Reason</Form.Label>
                        <Form.Control type='text' value={userInput} onChange={handleChange} placeholder='Reason'/>
                    </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button type='submit' disabled={userInput.length === 0} variant="primary" onClick={handleSaveChanges}>
                    Add
                </Button>
                </Modal.Footer>
            </Modal>
            
           
        </div>
  
    );
};

export default MoodForm;