import React, {useState, useEffect} from 'react';
import Header from "./components/Header";
import './App.css';
import MoodList from "./components/MoodList";
import MoodForm from "./components/MoodForm";
import AnalysisResults from './AnalysisResults';
import axios from 'axios';
import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button, Spinner, Stack, Tab, Tabs } from 'react-bootstrap';

const db = new Dexie('moods');
db.version(1).stores({
  moods: '++id, reason, moodType'
});

const providers = ['aad'];
const redirect = window.location.pathname;

function App() {

  // Must have all other useState() calls before useLiveQuery()
  const [happyReasons, setHappyReasons] = useState([]);
  const [happyIdeasToConsider, setHappyIdeasToConsider] = useState([]);
  const [sadReasons, setSadReasons] = useState([]);
  const [sadSolutionsToConsider, setSadSolutionsToConsider] = useState([]);
  const [moodFilter, setMoodFilter] = useState(['good', 'bad']);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [tabKey, setTabKey] = useState('home');

  useEffect(() => {
    (async () => {
      setUserInfo(await getUserInfo());
    })();
  }, []);

  async function getUserInfo() {
    try {
      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;
      return clientPrincipal;
    } catch (error) {
      console.error('No profile could be found');
      return undefined;
    }
  }

  const moods = useLiveQuery(() => db.moods.where("moodType").anyOf(moodFilter).toArray(), [moodFilter]);
  if (! moods) {
    return <div>Loading...</div>;
  }

  const handleFilter = (moodType) => {
    if (moodType === "all") {
      setMoodFilter(['good', 'bad']);
    }
    else {
      setMoodFilter([moodType]);
    }
  }

  const addMood = async (userInput, moodType) => {
    
    await db.moods.add({ reason: userInput, moodType: moodType });

  }

  const analyzeCommonHappinessThemes = async () => {

    try {
      const response = await axios.post(
        'api/moods/analyze/happy-mood-themes',
        moods.filter(m => m.moodType === "good"),
        {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
        }
      );

      setHappyReasons(response.data);
    } catch (e) {
      console.log(e);
      setHappyReasons([]);
    }
  }

  const analyzeMoreActivitiesForHappiness = async() => {

    try {
      const response = await axios.post(
        'api/moods/analyze/happy-activities',
        moods.filter(m => m.moodType === "good"),
        {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
        }
      );

      setHappyIdeasToConsider(response.data);
    }
    catch(e) {
      console.log(e);
      setHappyIdeasToConsider([]);
    }
  }

  const analyzeBadMoodThemes = async () => {

    try {
      const response = await axios.post(
        'api/moods/analyze/bad-mood-themes',
        moods.filter(m => m.moodType === "bad"),
        {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
        }
      );

      setSadReasons(response.data);
    }
    catch(e) {
      console.log(e);
      setSadReasons([]);
    }
  }

  const analyzeBadMoodSolutions = async () => {

    try {
      const response = await axios.post(
        'api/moods/analyze/bad-mood-solutions',
        moods.filter(m => m.moodType === "bad"),
        {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          }
        }
      );

      setSadSolutionsToConsider(response.data);
    }
    catch(e) {
      console.log(e);
      setSadReasons([]);
    }
  }

  const handleAnalyze = async () => {

    setIsAnalyzing(true);

    await analyzeCommonHappinessThemes();
    await analyzeMoreActivitiesForHappiness();
    await analyzeBadMoodThemes();
    await analyzeBadMoodSolutions();

    setIsAnalyzing(false);
    setTabKey('analysisResults');
  }

  const handleDelete = async (id) => {
    await db.moods.delete(id);
  }

  return (

   <div className="App">
      <Header />
      {userInfo && (<div className="user">
            <div>
              <span>Welcome, {userInfo && userInfo.userDetails}</span><span>(<a href={`/.auth/logout?post_logout_redirect_uri=${redirect}`}>Logout</a>)</span>
            </div>
          </div>
          )
      }
      <Tabs
        defaultActiveKey="home"
        id="main-tabs"
        activeKey={tabKey}
        onSelect={(k) => setTabKey(k)}
        className="mb-3 mt-3"
        justify
      >
        <Tab eventKey="home" title="Home">
          <Stack gap={3}>
          
          
          <div id='main-actions'>
            <MoodForm className='main-action' addMood={async (reason, moodType) => { await addMood(reason, moodType); }} />
            {userInfo && moods.length > 0 && <>
              <Button className='main-action' variant='secondary' disabled={isAnalyzing} id='analyzeButton' onClick={async () => { await handleAnalyze(); }}>
                {isAnalyzing && <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />Analyzing...
                </>}
              {!isAnalyzing && <>Analyze</>}
              </Button>
              </>}
          </div>
         
          <MoodList moodList={moods} handleFilter={handleFilter} handleDelete={async(id) => {await handleDelete(id)}} />
  
          {!userInfo && (moods.length > 0) &&
            providers.map((provider) => (
            <a href={`/.auth/login/${provider}?post_login_redirect_uri=${redirect}`}>
              Sign in to analyze
            </a>
          ))}
          
         
          </Stack>
        </Tab>
        <Tab eventKey="analysisResults" title="Analysis Results">
          <AnalysisResults 
            happyReasons={happyReasons} 
            happyIdeasToConsider={happyIdeasToConsider}
            sadReasons={sadReasons} 
            sadSolutionsToConsider={sadSolutionsToConsider} />
        </Tab>
        <Tab eventKey="about" title="About">
        This demonstration utilizes OpenAI GPT to analyze potential reasons behind an individual's mood. 
      Please note that this is not a commercial product and the accuracy of the results may be limited by the capabilities of the artificial intelligence. To ensure your safety and privacy, please do not enter any personal information. It is important to also be aware that the generated content may potentially be offensive or dangerous. The creators of this demonstration are not liable for any content generated through this tool.
  
        </Tab>
      </Tabs>
   </div>
  );
}

export default App;
